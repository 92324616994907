import { Injectable } from '@angular/core';
import { ModalRoutingService } from '@woolworthsnz/styleguide';
import { MODAL_ROUTES } from '../constants';

@Injectable({
	providedIn: 'root',
})
export class CancelChangeOrderService {
	message = "Our team are picking your order, so we can't make any changes.";

	constructor(private modalRoutingService: ModalRoutingService) {}

	get cancelChangeOrderMessage(): string {
		return this.message;
	}

	displayCancelOrderModal(message?: string | undefined): void {
		if (message) {
			this.message = message;
		}

		this.modalRoutingService.open(MODAL_ROUTES.MODAL_OUTLET, MODAL_ROUTES.CANCEL_CHANGE_ORDER_PATH);
	}
}
