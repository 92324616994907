import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextResponse, PriceResponse, ProductResponse } from '@woolworthsnz/trader-api';
import {
	CardFieldType,
	ChangeOrderEvent,
	ChangeOrderStatus,
	ChangeOrderSubmittedEvent,
	ChangeOrderTrackingEvent,
	DatalayerCheckoutStepsEnum,
	DatalayerEvent,
	DatalayerEventEnum,
	ExperimentEvent,
	FormFieldValidationEvent,
	NotificationTrackingEvent,
	NotificationEventWithInStockToggle,
	NotificationType,
	PageInfoEvent,
	ProductDatalayerEvent,
	RegistrationCompleteEvent,
	SearchPageInfoDetails,
	SearchType,
	SessionSettingsEvent,
	ShopperTrackingEvent,
	ProductImpressionData,
	BrowseMenuExperimentEvent,
	ProductType,
	TealiumEventEnum,
} from '../ui-models';
import { AppSettingsService } from './app-settings.service';
import { LoggingService } from './logging.service';
import { CustomWindow, WINDOW } from './window.service';
import { CookieAppSettingsService } from './cookie-app-settings.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FeatureService } from './feature.service';

/* eslint-disable no-console, no-restricted-syntax*/

interface KeyValue<T> {
	[key: string]: T;
}

export enum TrackingEvent {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	NotificationEvent = 'notification_event',
}

const isProductAvailable = (product?: ProductResponse): boolean =>
	!!product?.price?.salePrice && !!product?.quantity?.max;

@Injectable({
	providedIn: 'root',
})
export class DatalayerService {
	// When this is set to TRUE you'll get a tonne of console logs around all datalayer pushes
	enableDataLayerLogging = false;
	enableOnlineSampleRemarketingTracking = false;

	olsRemarketingCarouselName = 'Online Sample Remarketing';
	olsRemarketingCreativeName = 'HYF Remarketing';

	private noCarouselName = '**NONE**';
	private productImpressionBatches = new Map<string, ProductImpressionData[]>();
	private productImpressionBatchSubject = new Subject<void>();

	constructor(
		@Inject(PLATFORM_ID) private platform: any,
		@Inject(WINDOW) private window: CustomWindow,
		private loggingService: LoggingService,
		private route: ActivatedRoute,
		private appSettingsService: AppSettingsService,
		private cookieAppSettingsService: CookieAppSettingsService,
		private featureService: FeatureService
	) {
		this.initProductImpressionBatchSubscription();
	}

	get isShoppingListOpen(): any {
		return this.route.snapshot.queryParams && this.route.snapshot.queryParams.openShoppingList;
	}

	pushToDatalayer(
		event:
			| DatalayerEvent
			| PageInfoEvent
			| FormFieldValidationEvent
			| RegistrationCompleteEvent
			| ShopperTrackingEvent
			| SessionSettingsEvent
			| NotificationTrackingEvent
			| NotificationEventWithInStockToggle
			| ChangeOrderEvent
			| ChangeOrderTrackingEvent
			| ChangeOrderSubmittedEvent
			| ExperimentEvent
			| BrowseMenuExperimentEvent
	): void {
		if (isDevMode() && this.enableDataLayerLogging) {
			console.info('[DatalayerService]: pushToDatalayer called');
			console.table(event);
		}

		if (isPlatformBrowser(this.platform) && this.window['dataLayer']) {
			const callbackObject = this.getEventCallbackData(event);
			this.window['dataLayer'].push({
				...event,
				eventCallback: (gtmKey: string) => {
					if (gtmKey !== this.window?.BOOTSTRAP_DATA?.gtmKey) {
						return;
					}
					this.window['dataLayer'].push(callbackObject);
				},
			});
		}
	}

	getEventCallbackData(
		eventData:
			| DatalayerEvent
			| PageInfoEvent
			| FormFieldValidationEvent
			| RegistrationCompleteEvent
			| ShopperTrackingEvent
			| SessionSettingsEvent
	): KeyValue<undefined> {
		const callbackObject = Object.keys(eventData).reduce((prev: KeyValue<undefined>, value: string) => {
			prev[value] = undefined;
			return prev;
		}, {} as KeyValue<undefined>);

		delete callbackObject.event;
		return callbackObject;
	}

	onProductClick(productDatalayerEvent: ProductDatalayerEvent, url: string): void {
		if (!productDatalayerEvent.id) {
			return;
		}
		if (isDevMode() && this.enableDataLayerLogging) {
			console.info('[DatalayerService]: onProductClick called');
			console.log({ url, ...productDatalayerEvent });
		}

		const routeData = this.loggingService.getRouteData(this.route.snapshot);
		const gtmListname = this.isShoppingListOpen ? `shoppingList-${routeData.gtmListName}` : routeData.gtmListName;

		return this.pushToDatalayer({
			event: 'productClick',
			ecommerce: {
				click: {
					actionField: {
						list: gtmListname,
					},
					products: [productDatalayerEvent],
				},
			},
		});
	}

	getProductDetailsListType():
		| 'Shopping list'
		| 'Search'
		| 'Browse'
		| 'Home'
		| 'ProductGroup'
		| 'DynamicProductGroup'
		| 'ProductDetails'
		| 'HYF'
		| 'Recipe'
		| 'ProductDetail' {
		const previousUrl = this.cookieAppSettingsService.getCookieSetting('previousUrl') || '';
		return this.getListTypeByUrl(previousUrl) || 'ProductDetail';
	}

	addOrUpdateTrolley(productData: ProductDatalayerEvent): void {
		if (isDevMode() && console.table && this.enableDataLayerLogging) {
			console.info('[DatalayerService]: addOrUpdateTrolley called');
			console.table(productData);
		}

		if (!productData.id) {
			return;
		}

		const routeData = this.loggingService.getRouteData(this.route.snapshot);
		const pageName =
			routeData.gtmListName === 'ProductDetail' ? this.getProductDetailsListType() : routeData.gtmListName;

		const gtmListname = this.isShoppingListOpen ? `shoppingList-${pageName}` : pageName;

		this.loggingService.trackEvent(DatalayerEventEnum.AddToCart, productData);

		return this.pushToDatalayer({
			event: DatalayerEventEnum.AddToCart,
			ecommerce: {
				currencyCode: 'NZD',
				add: {
					actionField: { list: gtmListname },
					products: [productData],
				},
			},
		});
	}

	removeFromTrolley(productData: ProductDatalayerEvent): void {
		if (isDevMode() && console.table && this.enableDataLayerLogging) {
			console.info('[DatalayerService]: removeFromTrolley called');
			console.table(productData);
		}

		if (!productData.id) {
			return;
		}

		const routeData = this.loggingService.getRouteData(this.route.snapshot);
		const pageName =
			routeData.gtmListName === 'ProductDetail' ? this.getProductDetailsListType() : routeData.gtmListName;
		const gtmListname = this.isShoppingListOpen ? `shoppingList-${pageName}` : pageName;

		this.loggingService.trackEvent(DatalayerEventEnum.RemoveFromCart, productData);

		return this.pushToDatalayer({
			event: DatalayerEventEnum.RemoveFromCart,
			ecommerce: {
				currencyCode: 'NZD',
				remove: {
					actionField: { list: gtmListname },
					products: [productData],
				},
			},
		});
	}

	/**
	 * Maps product response objects to product data layer events
	 * Note: excludes non-product items in the grid, but preserves the position index
	 * so analytics reporting doesn't have duplicate records for a given position
	 *
	 * @param products
	 * @param name
	 * @param carouselName
	 */
	mapProductsToDatalayerEvent = (
		products: ProductResponse[] | ProductImpressionData[],
		name: any,
		carouselName?: string
	): any => {
		let ar: any = [];
		const isOnlineSampleRemarketingCarousel = carouselName === this.olsRemarketingCarouselName;

		products.map((p, i) => {
			if (p.type === 'Product') {
				let availabilityStatus = '';
				if (isProductAvailable(p)) {
					availabilityStatus = p.availabilityStatus === undefined ? 'In Stock' : p.availabilityStatus;
				} else {
					availabilityStatus = 'Unavailable';
				}

				const productType =
					isOnlineSampleRemarketingCarousel && this.enableOnlineSampleRemarketingTracking
						? ProductType.CartologyRemarketing
						: (<ProductImpressionData>p).productType;

				ar = [
					...ar,
					{
						id: p.sku,
						name: p.name,
						price: p.price ? p.price.salePrice : null,
						availabilityStatus,
						position: (<ProductImpressionData>p).position ?? i + 1,
						list: name || '',
						carouselName: carouselName,
						specialLabel: p.productTag?.tagType || '',
						productType: productType,
					},
				];
			}
		});

		return ar;
	};

	/**
	 * Maps product response objects to tealium events
	 * Note: excludes non-product items in the grid, but preserves the position index
	 * so analytics reporting doesn't have duplicate records for a given position
	 *
	 * @param products
	 */
	mapProductsToRemarketingTealiumEvent = (products: ProductResponse[] | ProductImpressionData[]): any => {
		let ar: any = [];

		products.map((p, i) => {
			if (p.type === 'Product') {
				ar = [
					...ar,
					{
						tealium_event: TealiumEventEnum.ViewPromotion,
						ecommerce: {
							creative_name: this.olsRemarketingCreativeName,
							creative_slot: (<ProductImpressionData>p).position ?? i + 1,
							promotion_id: p.onlineSample === undefined ? '' : p.onlineSample.campaignId,
							promotion_name: p.onlineSample === undefined ? '' : p.onlineSample.campaignName?.trim(),
							items: [
								{
									item_id: p.sku,
									item_name: p.name,
									index: (<ProductImpressionData>p).position ?? i + 1,
									item_variant: ProductType.CartologyRemarketing,
								},
							],
						},
					},
				];
			}
		});

		return ar;
	};

	trackProductImpressions(products: ProductResponse[] | ProductImpressionData[], carouselName?: string): void {
		if (!products || !products.length) {
			return;
		}

		const currentUrl = this.appSettingsService.getSetting('currentUrl') || '';
		const listType = this.getListTypeByUrl(currentUrl) || currentUrl;

		this.enableOnlineSampleRemarketingTracking = this.featureService.isFeatureEnabled(
			ContextResponse.EnabledFeaturesEnum.EnableHaveYouForgottenGATracking
		);

		const productDatalayerEvent = this.mapProductsToDatalayerEvent(products, listType, carouselName);

		this.loggingService.trackEvent(DatalayerEventEnum.ProductImpressions, productDatalayerEvent);

		if (isDevMode() && console.table && this.enableDataLayerLogging) {
			console.info('[DatalayerService]: trackProductImpressions called');
			console.table(productDatalayerEvent);
		}

		return this.pushToDatalayer(<DatalayerEvent>{
			event: DatalayerEventEnum.ProductImpressions,
			ecommerce: {
				currencyCode: 'NZD',
				impressions: [...productDatalayerEvent],
			},
		});
	}

	getListTypeByUrl(
		url = ''
	):
		| 'Shopping list'
		| 'Search'
		| 'Browse'
		| 'Home'
		| 'ProductGroup'
		| 'DynamicProductGroup'
		| 'ProductDetails'
		| 'HYF'
		| 'Recipe'
		| undefined {
		if (url.match(/shop\/searchproducts.*openShoppingList/)) {
			return 'Shopping list';
		}
		if (url.match(/shop\/searchproducts/)) {
			return 'Search';
		}
		if (url.match(/shop\/browse/)) {
			return 'Browse';
		}
		if (url === '/') {
			return 'Home';
		}
		if (url.match(/shop\/productgroup/)) {
			return 'ProductGroup';
		}
		if (url.match(/shop\/products/)) {
			return 'DynamicProductGroup';
		}
		if (url.match(/shop\/productdetails/)) {
			return 'ProductDetails';
		}
		if (url.match(/haveyouforgotten/)) {
			return 'HYF';
		}
		if (url.match(/recipes/)) {
			return 'Recipe';
		}
	}

	trackProductDetailView(sku: string, tagType?: string, price: PriceResponse = {}, adId = ''): void {
		const previousUrl = this.cookieAppSettingsService.getCookieSetting('previousUrl');
		const currentUrl = this.appSettingsService.getSetting('currentUrl');
		const list = this.getListTypeByUrl(previousUrl || currentUrl) || '';
		const productData: ProductDatalayerEvent = {
			id: sku,
			price: price.salePrice || 0,
			...(adId && { cartologySponsoredProduct: 'Yes' }),
		};

		if (!!tagType) {
			productData.specialLabel = tagType;
		}

		return this.pushToDatalayer({
			event: DatalayerEventEnum.ProductDetail,
			ecommerce: {
				detail: {
					actionField: {
						list,
					},
					products: [productData],
				},
			},
		});
	}

	trackSearchPageView(details: SearchPageInfoDetails): void {
		const routeData = this.loggingService.getRouteData(this.route.snapshot);
		const gtmListName = routeData.gtmListName;

		this.pushToDatalayer({
			event: DatalayerEventEnum.PageInfo,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			page_type: gtmListName,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			page_content: details.pageContent,
			navigation: [gtmListName, ...(details.navigation || [])], // must start with 'Search'/'Browse' etc
			filters: details.filters,
			sortBy: details.sortBy,
			pagination: details.pagination,
			itemsPerPage: details.itemsPerPage,
			...(details.itemsInResultsSet && { itemsInResultsSet: details.itemsInResultsSet }),
			organicItemCount: details.organicItemCount,
			cppItemCount: details.cppItemCount,
			gridPromotionCount: details.gridPromotionCount,
		});
	}

	trackPageInfo(listName: string, details: SearchPageInfoDetails): void {
		this.pushToDatalayer({
			event: DatalayerEventEnum.PageInfo,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			page_type: listName,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			page_content: details.pageContent,
			filters: details.filters,
			sortBy: details.sortBy,
			pagination: details.pagination,
			itemsPerPage: details.itemsPerPage,
		});
	}

	trackShopper(data: ShopperTrackingEvent): void {
		return this.pushToDatalayer({
			event: DatalayerCheckoutStepsEnum.ShopperTrackingEvent,
			...data,
		});
	}

	trackSessionSettings(settings: SessionSettingsEvent): void {
		const { fulfillmentMethod, fulfillmentWindow, areaId, storeId } = settings;
		const isValid = !!fulfillmentMethod && !!fulfillmentWindow && !!areaId && !!storeId;
		if (!isValid) {
			return;
		}

		return this.pushToDatalayer({ event: DatalayerEventEnum.SessionSettings, ...settings });
	}

	trackBannerImpression(data: any): void {
		const currentUrl = this.appSettingsService.getSetting('currentUrl');
		const listType = this.getListTypeByUrl(currentUrl);
		this.trackPromoImpression({ ...data, creative: `${listType} Banner` });
	}

	trackBannerClick(data: any): void {
		const currentUrl = this.appSettingsService.getSetting('currentUrl');
		const listType = this.getListTypeByUrl(currentUrl);
		this.trackPromoClick({ ...data, creative: `${listType} Banner` });
	}

	trackPromoClick(data: any): void {
		return this.pushToDatalayer({
			event: DatalayerEventEnum.PromotionClick,
			ecommerce: {
				promoClick: {
					promotions: [].concat(data),
				},
			},
		});
	}

	trackPromoImpression(data: any): void {
		return this.pushToDatalayer({
			event: DatalayerEventEnum.PromotionImpression,
			ecommerce: {
				promoView: {
					promotions: [].concat(data),
				},
			},
		});
	}

	trackNotificationEventWithInStockToggle(
		type: NotificationType,
		inStockToggle: string,
		name: string,
		value?: string | number
	): void {
		this.pushToDatalayer({
			event: TrackingEvent.NotificationEvent,
			notification_type: type,
			name,
			value,
			in_stock_toggle: inStockToggle,
		});
	}

	trackNotificationEvent(type: NotificationType, name: string, value?: string | number): void {
		return this.trackEvent(TrackingEvent.NotificationEvent, type, name, value);
	}

	trackServiceBarIconEvent(name: string): void {
		return this.trackEvent(TrackingEvent.NotificationEvent, NotificationType.ServiceBar, name);
	}

	trackImageNavigationItemEvent(name: string): void {
		return this.trackEvent(TrackingEvent.NotificationEvent, NotificationType.ImageNavigation, name);
	}

	trackRecipeEvent(type: NotificationType, name: string, id?: number): void {
		return this.trackEvent(TrackingEvent.NotificationEvent, type, name.substring(0, 32), id || '');
	}

	trackStoreEvent(type: NotificationType, name: string): void {
		return this.trackEvent(TrackingEvent.NotificationEvent, type, name.substring(0, 32), '');
	}

	trackSearchEvent(type: SearchType, name: string, value?: string): void {
		return this.pushToDatalayer({
			event: TrackingEvent.NotificationEvent,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			notification_type: type === SearchType.Grocery ? NotificationType.Grocery : NotificationType.Recipe,
			name: name.substring(0, 32),
			value,
		});
	}

	trackRecipeAddToTrolley(productEvents: ProductDatalayerEvent[]): void {
		if (!productEvents?.length) {
			return;
		}

		const currentUrl = this.appSettingsService.getSetting('currentUrl');
		const listType = this.getListTypeByUrl(currentUrl) || '';

		if (isDevMode() && console.table && this.enableDataLayerLogging) {
			console.info('[DatalayerService]: Recipe addToTrolley called');
			console.table(productEvents);
		}

		this.loggingService.trackEvent(DatalayerEventEnum.AddToCart, productEvents);

		return this.pushToDatalayer({
			event: DatalayerEventEnum.AddToCart,
			ecommerce: {
				currencyCode: 'NZD',
				add: {
					actionField: { list: listType },
					products: productEvents,
				},
			},
		});
	}

	// Change Order Tracking
	trackChangeOrderEnabled(changeOrderEvent: ChangeOrderEvent): void {
		this.pushToDatalayer({
			event: DatalayerEventEnum.ChangeOrderEnabled,
			...changeOrderEvent,
		});

		this.trackChangeOrderStatus({
			amendedTransactionId: changeOrderEvent.amendedTransactionId,
			revisionNumber: changeOrderEvent.eventDetails?.revisionNumber,
			changeMyOrderStatus: ChangeOrderStatus.enabled,
		});
	}

	trackChangeOrderDisabled(changeOrderEvent: ChangeOrderEvent): void {
		this.pushToDatalayer({
			event: DatalayerEventEnum.ChangeOrderDisabled,
			...changeOrderEvent,
		});

		this.trackChangeOrderStatus({
			amendedTransactionId: changeOrderEvent.amendedTransactionId,
			revisionNumber: changeOrderEvent.eventDetails?.revisionNumber,
			changeMyOrderStatus: ChangeOrderStatus.disabled,
		});
	}

	trackChangeOrderSubmitted(changeOrderSubmittedEvent: ChangeOrderSubmittedEvent): void {
		this.pushToDatalayer({
			event: DatalayerEventEnum.ChangeOrderSubmitted,
			...changeOrderSubmittedEvent,
		});

		this.trackChangeOrderStatus({
			amendedTransactionId: changeOrderSubmittedEvent.amendedTransactionId,
			revisionNumber: '1',
			changeMyOrderStatus: ChangeOrderStatus.disabled,
		});
	}

	trackChangeOrderStatus(changeOrderTrackingEvent: ChangeOrderTrackingEvent): void {
		return this.pushToDatalayer({
			event: DatalayerEventEnum.ChangeOrderTracking,
			...changeOrderTrackingEvent,
		});
	}

	trackCardEvent(cardFieldType: CardFieldType, valid: boolean): void {
		return this.pushToDatalayer({
			event: TrackingEvent.NotificationEvent,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			notification_type: NotificationType.CreditCard,
			name: cardFieldType,
			value: valid ? 'valid' : 'invalid',
		});
	}

	trackEvent(event: TrackingEvent, type: NotificationType, name: string, value?: string | number): void {
		return this.pushToDatalayer({
			event,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			notification_type: type,
			name,
			value,
		});
	}

	/**
	 * This will add a product impressions to an existing batch of product impressions.
	 * It also triggers the observer event
	 * @param products
	 * @param carouselName
	 */
	addProductImpressionsToBatch(products: ProductImpressionData[], carouselName?: string): void {
		const key = carouselName || this.noCarouselName;

		if (this.productImpressionBatches.has(key)) {
			const batchedProducts = this.productImpressionBatches.get(key) ?? [];
			this.productImpressionBatches.set(key, [...batchedProducts, ...products]);
		} else {
			this.productImpressionBatches.set(key, [...products]);
		}

		this.productImpressionBatchSubject.next();
	}

	private initProductImpressionBatchSubscription(): void {
		this.productImpressionBatchSubject
			// We want to batch up product impressions and send in one event, wait for the UI to settle down before
			// we send a batch
			.pipe(debounceTime(500))
			.subscribe(() => {
				this.productImpressionBatches.forEach((products: ProductImpressionData[], key: string) => {
					const carouselName = key !== this.noCarouselName ? key : undefined;
					this.trackProductImpressions(products, carouselName);
					this.productImpressionBatches.set(key, []);
				});
			});
	}

	trackAllowSubs(products: ProductResponse[]): void {
		const skusWithSubsAllowed: string[] = [];
		const skusWithSubsDisallowed: string[] = [];

		products.forEach(({ subsAllowed, sku = '' }) =>
			subsAllowed ? skusWithSubsAllowed.push(sku) : skusWithSubsDisallowed.push(sku)
		);

		this.trackNotificationEvent(
			NotificationType.OrderSubstitution,
			'order_substituted',
			products.every((product) => product.subsAllowed) ? 'yes' : 'no'
		);

		if (skusWithSubsAllowed.length !== 0) {
			this.trackNotificationEvent(
				NotificationType.OrderSubstitution,
				'products_substituted',
				skusWithSubsAllowed.toString()
			);
		}

		if (skusWithSubsDisallowed.length !== 0) {
			this.trackNotificationEvent(
				NotificationType.OrderSubstitution,
				'products_not_substituted',
				skusWithSubsDisallowed.toString()
			);
		}
	}
}
