import {
	AfterViewInit,
	CUSTOM_ELEMENTS_SCHEMA,
	Component,
	HostBinding,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	isDevMode,
} from '@angular/core';
import { HOW_WHERE_WHEN_PATH, VIEW_TROLLEY_PATH } from '@woolworthsnz/shop';
import {
	FlagKey,
	FlagService,
	PerformanceTrackingService,
	ShopperService,
	FeatureService,
	BreadcrumbComponent,
	EmbeddedVisibilityDirective,
	ModalViewComponent,
	OliveRoundelComponent,
	WappleLoadingComponent,
	SvgDefinitionsComponent,
	WINDOW,
	CustomWindow,
	AppSettingsService,
	NewLayoutOfProductCategoriesAndFiltersVariants,
	NewLayoutOfProductCategoriesAndFiltersVariables,
	NewLayoutOfProductCategoriesAndFiltersExp2Variants,
	DynatraceService,
} from '@woolworthsnz/styleguide';
import {
	Observable,
	distinctUntilChanged,
	of,
	switchMap,
	withLatestFrom,
	forkJoin,
	map,
	Subject,
	takeUntil,
} from 'rxjs';
import { AppSettings } from './app.settings';
import { AuthenticationService } from './authentication/services/authentication.service';
import { AppService } from './core/services/app.service';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { IamHiddenIframComponent } from './core/components/iam-hidden-iframe/iam-hidden-iframe.component';
import { RouterOutlet } from '@angular/router';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { ContextResponse } from '@woolworthsnz/trader-api';
import EnabledFeaturesEnum = ContextResponse.EnabledFeaturesEnum;
import { SearchStoreService } from './search/search-store.service';
import { SpaNavigationService } from '@woolworthsnz/product';

@Component({
	selector: 'wnz-content',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		NgIf,
		NgClass,
		RouterOutlet,
		BreadcrumbComponent,
		EmbeddedVisibilityDirective,
		SvgDefinitionsComponent,
		WappleLoadingComponent,
		OliveRoundelComponent,
		ModalViewComponent,
		IamHiddenIframComponent,
		AsyncPipe,
	],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
	@HostBinding('attr.data-renderer') originalRenderer = this.platformId.toString();

	timeslotRoute = `/${HOW_WHERE_WHEN_PATH}`;
	trolleyLink = VIEW_TROLLEY_PATH;
	// TODO - move to global nav
	navs = AppSettings.navs;

	public isNavExperiment7VariationActive$: Observable<boolean>;
	public checkSSU$: Observable<boolean>;
	newLayoutOfProductCategoriesAndFiltersVariant$: Observable<
		NewLayoutOfProductCategoriesAndFiltersVariants | NewLayoutOfProductCategoriesAndFiltersExp2Variants | undefined
	>;
	shouldShowNewLayoutOfProductCategoriesAndFilters$: Observable<boolean>;
	newLayoutOfProductCategoriesAndFiltersVariables$: Observable<
		NewLayoutOfProductCategoriesAndFiltersVariables | undefined
	>;
	private destroyed$: Subject<boolean> = new Subject();

	constructor(
		public appService: AppService,
		public authenticationService: AuthenticationService,
		private performanceTrackingService: PerformanceTrackingService,
		@Inject(PLATFORM_ID) private platformId: Object,
		private flagService: FlagService,
		private tealiumUtagService: TealiumUtagService,
		private shopperService: ShopperService,
		private featureService: FeatureService,
		@Inject(WINDOW) private window: CustomWindow,
		public searchStoreService: SearchStoreService,
		public appSettingsService: AppSettingsService,
		private dynatraceService: DynatraceService,
		private _spaNavigationService: SpaNavigationService
	) {
		this.performanceTrackingService.startTracking('AppComponentAfterPaint');
		this.isNavExperiment7VariationActive$ = this.flagService.someFlagVariationActive(FlagKey.globalNavExperiment, [
			'v1_a',
			'v1_b',
			'v1_c',
		]);
	}

	ngAfterViewInit(): void {
		this.shouldShowNewLayoutOfProductCategoriesAndFilters$ = this.searchStoreService.select('products').pipe(
			distinctUntilChanged(),
			withLatestFrom(
				this.newLayoutOfProductCategoriesAndFiltersVariant$,
				this.newLayoutOfProductCategoriesAndFiltersVariables$
			),
			switchMap(([_, variantKey, variables]) => {
				const searchTarget = this.searchStoreService.state.target;

				if (variantKey && variables) {
					return of(
						[
							NewLayoutOfProductCategoriesAndFiltersVariants.V3,
							NewLayoutOfProductCategoriesAndFiltersExp2Variants.Control,
							NewLayoutOfProductCategoriesAndFiltersExp2Variants.V1,
							NewLayoutOfProductCategoriesAndFiltersExp2Variants.V2,
						].includes(variantKey) &&
							searchTarget !== undefined &&
							variables.searchTargets.split(',').includes(searchTarget)
					);
				} else {
					return of(false);
				}
			})
		);
		this.performanceTrackingService.endPaintTracking('AppComponentAfterPaint');
		this.dynatraceService.triggerAction('ui-load-success');
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	ngOnInit(): void {
		if (isDevMode()) {
			this.authenticationService.loadGigyaJS();
		}
		this.shopperService.state$.pipe(takeUntil(this.destroyed$)).subscribe((_) => {
			this.tealiumUtagService.setSharedData(this.shopperService.getAnalyticsData());
		});
		this.checkSSU$ = this.featureService.isEnabled(EnabledFeaturesEnum.CheckSSU);

		this.newLayoutOfProductCategoriesAndFiltersVariant$ = forkJoin([
			this.flagService.getVariationKey<NewLayoutOfProductCategoriesAndFiltersVariants>(
				FlagKey.newLayoutOfProductCategoriesAndFilters
			),
			this.flagService.getVariationKey<NewLayoutOfProductCategoriesAndFiltersExp2Variants>(
				FlagKey.newLayoutOfProductCategoriesAndFiltersExp2
			),
		]).pipe(
			map((variants) =>
				variants.find((variant) => variant !== NewLayoutOfProductCategoriesAndFiltersVariants.Off)
			)
		);

		this.newLayoutOfProductCategoriesAndFiltersVariables$ = forkJoin([
			this.flagService.getVariables<NewLayoutOfProductCategoriesAndFiltersVariables>(
				FlagKey.newLayoutOfProductCategoriesAndFilters
			),
			this.flagService.getVariables<NewLayoutOfProductCategoriesAndFiltersVariables>(
				FlagKey.newLayoutOfProductCategoriesAndFiltersExp2
			),
		]).pipe(map((variablesList) => variablesList.find(({ searchTargets }) => !!searchTargets)));
	}

	handleMessage(event: any): void {
		if (event.origin !== this.appSettingsService.apiUrl && event.origin !== this.window.location.origin) {
			return;
		}
		if (
			event.origin === this.window.location.origin &&
			event.data === 'silentLoginCheckComplete' &&
			!this.shopperService.state$.value?.isLoggedIn
		) {
			// Just need to call any endpoint on the BFF that has the shopper context attached so that ContextInterceptor can set the appropriate states
			this.shopperService.getExperiments().pipe(takeUntil(this.destroyed$)).subscribe();
		}
	}
}
