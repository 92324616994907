import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { FulfilmentState, FulfilmentStoreService, SlotExpiryService } from '@woolworthsnz/fulfilment';
import {
	AppSettingsService,
	CustomWindow,
	FeatureService,
	HistoryService,
	ShopperService,
	WINDOW,
} from '@woolworthsnz/styleguide';
import { ContextResponse, ShopperContextResponse } from '@woolworthsnz/trader-api';
import equal from 'fast-deep-equal';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BasketService } from '../services/basket.service';

@Injectable()
export class ContextInterceptor implements HttpInterceptor {
	constructor(
		private appSettingsService: AppSettingsService,
		private basketService: BasketService,
		private fulfilmentStoreService: FulfilmentStoreService,
		private featureService: FeatureService,
		private shopperService: ShopperService,
		private slotExpiryService: SlotExpiryService,
		private router: Router,
		private historyService: HistoryService,
		@Inject(WINDOW) private window: CustomWindow,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse && event?.body?.context) {
					const { advancedSettingsResponse, shopper, fulfilment, basketTotals } = event.body.context;

					// Shopper state comparison
					this.compareOldAndNewState(this.shopperService, shopper);

					this.updateShopperStateForLegacy(shopper);

					// Feature state comparison
					// FIXME: This currently fires every time as the state is monkey patched in the UI
					this.compareOldAndNewState(this.featureService, {
						enabledFeatures: <ContextResponse.EnabledFeaturesEnum>event.body.context.enabledFeatures,
						updatedFromServer: true,
					});

					// Fulfilment state comparison
					this.compareOldAndNewState(this.fulfilmentStoreService, {
						...fulfilment,
						updatedFromServer: true,
					});

					// Basket state comparison
					this.compareOldAndNewState(this.basketService, basketTotals);

					if (shopper?.isChangingOrder) {
						// When an action has been performed,
						// If the shopper is changing their past order,
						// the slot reserved is already allocated to them,
						// so we do not show the slot expiry or closing timer.
						this.clearDeliverySlotTimers();
					} else if (fulfilment?.expressFulfilment?.isExpressSlot) {
						// When an action has been performed,
						// If shopper is shopping express, we reserve
						// the slot without activating closing timer.
						// This is to ensure the window doesn't close
						// on the shopper when cutoff is reached.
						// Expiry timers still work as intended.
						this.slotExpiryService.setExpressSlotFlag(fulfilment?.expressFulfilment?.isExpressSlot);
						this.slotExpiryService.clearClosingTimer();
						this.slotExpiryService.handleExpiryTimer();
					} else {
						// When an action has been performed we reset the expiry timer
						// We also set the closing timer (if the slot is today)
						// to ensure that, if the slot has magically changed
						// we catch it and act accordingly
						this.resetTimers(
							fulfilment?.cutOffTime,
							fulfilment?.isSlotToday,
							fulfilment?.expressFulfilment?.isExpressSlot
						);
					}

					// AdvancedSettings come from the backend. They're the same as settings,
					// but keeping under this key makes it easy to distinguish them
					this.appSettingsService.setState({
						advancedSettings: advancedSettingsResponse,
						updatedFromServer: true,
					});
				}
			})
		);
	}

	compareOldAndNewState(
		service: ShopperService | FeatureService | FulfilmentStoreService | BasketService,
		newState: any
	): void {
		const oldState = service.state;

		if (equal(oldState, newState)) {
			return;
		}

		if (
			service instanceof FulfilmentStoreService &&
			(oldState as FulfilmentState).startTime &&
			!newState.startTime
		) {
			// if there was previously a slot but now isn't reload the route... invoking any guards and resolvers etc
			const url = this.historyService.getHistory().pop();
			if (url) {
				this.router.navigateByUrl(url);
			}
		}

		if (service instanceof FulfilmentStoreService) {
			const isExpressAbandoned =
				!!(oldState as FulfilmentState).expressFulfilment?.isExpressSlot && !newState.isExpressSlot;
			newState.isExpressAbandoned = isExpressAbandoned;

			const expressPickUpFee = newState.expressFulfilment?.expressPickUpFee;
			const existingExpressPickupFee = this.appSettingsService.getExpressFulfilmentSetting('expressPickUpFee');
			if (expressPickUpFee && expressPickUpFee !== existingExpressPickupFee) {
				const expressFulfilmentSettings = this.appSettingsService.settings?.expressFulfilmentSettings;

				if (expressFulfilmentSettings) {
					this.appSettingsService.setState({
						expressFulfilmentSettings: { ...expressFulfilmentSettings, expressPickUpFee },
					});
				}
			}

			const expressDeliveryFee = newState.expressFulfilment?.expressDeliveryFee;
			const existingExpressDeliveryFee =
				this.appSettingsService.getExpressFulfilmentSetting('expressDeliveryFee');
			if (expressDeliveryFee && expressDeliveryFee !== existingExpressDeliveryFee) {
				const expressFulfilmentSettings = this.appSettingsService.settings?.expressFulfilmentSettings;

				if (expressFulfilmentSettings) {
					this.appSettingsService.setState({
						expressFulfilmentSettings: { ...expressFulfilmentSettings, expressDeliveryFee },
					});
				}
			}
		}

		service.setState(newState);
	}

	resetTimers(cutOffTime: string, isSlotToday: boolean, isExpressSlot?: boolean): void {
		if (isPlatformBrowser(this.platformId)) {
			this.slotExpiryService.handleReservationTimers(cutOffTime, isSlotToday, isExpressSlot);
		}
	}

	clearDeliverySlotTimers(): void {
		this.slotExpiryService.clearClosingTimer();
		this.slotExpiryService.clearExpiryTimer();
	}

	// Current weekly mailer is *legacy* and expects a bootstrap value for add to cart logic
	updateShopperStateForLegacy(shopper: ShopperContextResponse): void {
		this.window.BOOTSTRAP_DATA = this.window.BOOTSTRAP_DATA || {};
		this.window.BOOTSTRAP_DATA.isShopperLoggedIn = !!shopper?.isShopper;
		this.window.BOOTSTRAP_DATA.isLoggedIn = !!shopper?.isLoggedIn;
	}
}
