/* eslint-disable @typescript-eslint/naming-convention */
export type PickupType = 'Locker' | 'InStore' | 'DirectToBoot' | 'DriveUp' | 'DriveThrough';
export const PickupType = {
	ELocker: 'ELocker' as PickupType,
	Locker: 'Locker' as PickupType,
	InStore: 'InStore' as PickupType,
	DirectToBoot: 'DirectToBoot' as PickupType,
	DriveUp: 'DriveUp' as PickupType,
	DriveThrough: 'DriveThrough' as PickupType,
};
