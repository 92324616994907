import { Injectable } from '@angular/core';
import { CancelChangeOrderService } from './cancel-change-order.service';
import { ContextResponse, ShopperChangeOrderCheck } from '@woolworthsnz/trader-api';
import { FeatureService } from '@woolworthsnz/styleguide';

@Injectable({
	providedIn: 'root',
})
export class CheckChangeOrderService {
	constructor(
		private cancelChangeOrderService: CancelChangeOrderService,
		private featureService: FeatureService
	) {}

	handleShopperChangeOrderCheck(response: ShopperChangeOrderCheck | undefined): void {
		if (
			this.featureService.isFeatureEnabled(ContextResponse.EnabledFeaturesEnum.EnableCheckChangeOrderOnLoad) ===
				false ||
			response === undefined ||
			response === null ||
			response.isInChangeOrder === true ||
			response.isChangable === true ||
			response.changeOrderCancelled === false
		) {
			return;
		}

		this.cancelChangeOrderService.displayCancelOrderModal(
			this.getCancelChangeOrderMessage(response.changeOrderCancelledReason)
		);
	}

	private getCancelChangeOrderMessage(
		reason: ShopperChangeOrderCheck.ChangeOrderCancelledReasonEnum | undefined
	): string | undefined {
		switch (reason) {
			case ShopperChangeOrderCheck.ChangeOrderCancelledReasonEnum.CancelledOrder:
			case ShopperChangeOrderCheck.ChangeOrderCancelledReasonEnum.OrderNotFound:
				return "We're still processing your original order. Please try again in a few minutes.";
			case ShopperChangeOrderCheck.ChangeOrderCancelledReasonEnum.PaymentProviderNotSupported:
			case ShopperChangeOrderCheck.ChangeOrderCancelledReasonEnum.PaymentTypeNotSupported:
				return 'We are experiencing technical difficulties, please try again later. Sorry for the inconvenience.';
			default:
				return undefined;
		}
	}
}
