// TODO: Move all routes here at some point
export const HOME_PATH = '/';
export const ERROR_PATH = 'something-went-wrong';
export const NOT_FOUND_PATH = 'not-found';

export const HOW_WHERE_WHEN_PATH = 'bookatimeslot';
export const CHECKOUT_HOW_WHERE_WHEN_PATH = 'checkout/bookatimeslot';

export const HAVE_YOU_FORGOTTEN_PATH = 'haveyouforgotten';
export const LEGACY_HAVE_YOU_FORGOTTEN_PATH = 'shop/checkout';

export const REVIEW_ORDER_PATH = 'checkout/revieworder';

export const COMPLETE_LOGIN_PATH = 'shop/securelogin';
export const LOGIN_PATH = 'securelogin';
export const REGISTRATION_PATH = 'register';
export const REGISTRATION_SUCCESSFUL_PATH = 'welcome';
export const REGISTRATION_SHOPPER_DETAILS_PATH = 'register/shopperdetails';
export const ACCOUNT_DETAILS_PATH = 'shop/accountdetails';
export const LEGACY_ACCOUNT_SETTINGS_PATH = 'shop/advancedsettings';
export const FORGOT_PASSWORD_PATH = 'forgotpassword';
export const RESET_PASSWORD_PATH = 'resetpassword';
export const LEGACY_FORGOT_PASSWORD_PATH = 'shop/passwordreminder';
export const WELCOME_PAGE_PATH = 'shop/welcome';

export const VIEW_TROLLEY_PATH = 'reviewtrolley';
export const CHANGE_ORDER_LANDING_PATH = 'changeorder/landing';

export const PAYMENTS_PATH = 'checkout/payment';
export const CHANGE_ORDER_PAYMENTS_PATH = 'changeorder/payment';
export const ORDER_CONFIRMATION_PATH = 'checkout/confirmation';

export const LISTS_PATH = 'lists';
export const FAVOURITES_PATH = 'myfavourites';
export const LEGACY_FAVOURITES_PATH = 'shop/favourites';

export const PAST_ORDERS_PATH = 'pastorders';

export const SAVED_LISTS_PATH = 'saved';
export const SAVED_RECIPES_PATH = 'recipes';
export const LEGACY_SAVED_RECIPES_PATH = 'shop/savedrecipes';

export const SHOPPING_LISTS_PATH = 'shop/editsearchlist';

export const BROWSE_PATH = 'shop/browse';

export const BROWSE_DEPARTMENTS_PATH = 'shop/browse/departments';

export const SPECIALS_PATH = 'shop/specials';

export const RECIPES_PATH = 'recipes';

export const ACCOUNT_PATH = 'account';
export const ACCOUNT_PERSONAL_DETAILS_PATH = 'personal-details';
export const ACCOUNT_DELIVERY_ADDRESSES_PATH = 'delivery-addresses';
export const ACCOUNT_EMAIL_PASSWORD_PATH = 'email-password';
export const ACCOUNT_OLS_PREFERENCES_PATH = 'preferences';
export const ACCOUNT_ONECARD_DASHBOARD_PATH = 'onecard/dashboard';
export const ACCOUNT_ONECARD_FOR_OLS_PATH = 'onecard';
export const ACCOUNT_MY_ORDERS_PATH = 'orders';
export const ACCOUNT_MY_SUBSCRIPTIONS = 'subscriptions';

export const SEARCH_LIST_ITEMS_PATH = 'listitems';
export const SEARCH_PRODUCTS_PATH = 'searchproducts';

export const MANAGE_ADDRESS_PATH = 'shop/manageaddress';

export const DELIVERYSUBSCRIPTION_PATH = 'deliverysubscription';
export const DELIVERYSUBSCRIPTION_PAYMENT_PATH = 'deliverysubscription/payment';
export const DELIVERYSUBSCRIPTION_PAYMENT_SUCCESS_PATH = 'deliverysubscription/payment/success';
export const DELIVERYSUBSCRIPTION_PLANS_PATH = 'deliverysubscription/plans';

export const STORE_LOCATOR_PATH = 'store-finder';

export const EXPRESS_LANDING_PAGE_PATH = 'express';

export const NZ_GROCERY_WHOLESALERS_PAGE_PATH = 'info/about-us/about-woolworths-nz';

export const ABOUT_US_PATH = 'about-us';
export const CONTACT_US_PATH = 'contact-us';

export const LIQUOR_LICENSES_PATH = 'shop/content/liquor-licences';
export const PRIVACY_POLICY_PATH = 'info/privacy';
export const TERM_CONDITIONS_PATH = 'info/terms-and-conditions';

export const MEAL_PLANNING_PATH = 'meal-planning';
export const MEAL_PLANNING_CHOOSE_MEALS_PATH = 'choose-meals';
export const MEAL_PLANNING_REVIEW_PLAN_PATH = 'review-plan';
export const MEAL_PLANNING_MY_MEAL_PLANS = 'my-meal-plans';

export const EDR_LANDING_PAGE_PATH = 'everyday-rewards';
export const EDR_DASHBOARD_PATH = 'everyday-rewards/dashboard';
export const EDR_BOOST_OFFERS_PATH = 'shop/everyday-rewards-boosts';

/* eslint-disable @typescript-eslint/naming-convention */
export const MODAL_ROUTES = {
	MODAL_OUTLET: 'modal',
	ACCOUNT_MODAL_OUTLET: 'account-modal',
	OFFER_MODAL_OUTLET: 'offer-modal',
	DELIVERY_MODAL_OUTLET: 'delivery-modal',
	EXPRESS_MODAL: 'express-modal',
	HWW_MODAL: 'hww-modal',
	HOME_MODAL: 'info-modal',
	MEAL_PLANNING_MODAL: 'mp-modal',
	PAYMENT_MODAL: 'payment-modal',
	PRODUCT_DETAILS_MODAL: 'pd-modal',
	RECIPE_MODAL: 'recipe-modal',
	REVIEW_ORDER_MODAL: 'ro-modal',
	ORDER_CONFIRMATION_MODAL: 'oc-modal',
	VIEW_TROLLEY_MODAL: 'vt-modal',
	CHANGE_DELIVERY_ADDRESS_PATH: 'change-delivery-address',
	CHANGE_PICK_UP_STORE_PATH: 'change-pick-up-store',
	DELIVERY_FEES_PATH: 'delivery-fees',
	EXPRESS_DELIVERY_FEES_PATH: 'express-delivery-fees',
	ADD_DELIVERY_ADDRESS_PATH: 'add-delivery-address',
	EMPTY_TROLLEY_PATH: 'empty-trolley',
	CLEAR_TROLLEY_CONFIRM_PATH: 'clear-trolley',
	FORCED_PICKUP_ON_HOMEPAGE_PATH: 'switched-to-pickup',
	CHANGE_CONTACT_DETAILS: 'change-contact-details',
	PRICE_POLICY_PATH: 'price-policy',
	CHANGE_ORDER_PATH: 'change-order',
	PURCHASE_BY_QUANTITY_MODAL_PATH: 'purchase-by-quantity-message',
	CHANGE_ORDER_ONBOARDING_MODAL_PATH: 'change-order-onboarding',
	CHANGE_ORDER_CANCEL_PATH: 'change-order-cancel',
	REGISTER_ONECARD_PATH: 'register-onecard',
	PICKUP_INFORMATION: 'pickup-information',
	SAVE_AS_NEW_LIST_PATH: 'save-as-list',
	PACKAGING_INFORMATION: 'packaging-information',
	EXPRESS_ITEM_LIMIT: 'express-item-limit',
	CANCEL_SUBSCRIPTION_MODAL_PATH: 'cancel-subscription',
	CHANGE_PAYMENT_MODAL_PATH: 'change-payment',
	SUBSCRIPTION_ADDRESS_CHECKER: 'subscription-address-checker',
	SUBSCRIPTION_ADDRESS_ELIGIBILITY: 'subscription-address-eligibility',
	CHANGE_SUBSCRIPTION_PAYMENT_INSTRUMENT: 'change-subscription-payment-instrument',
	CHANGE_SUBSCRIPTION_PAYMENT_INSTRUMENT_V2: 'change-subscription-payment-instrument-v2',
	CHANGE_PAYMENT_INSTRUMENT: 'change-payment-instrument',
	CHANGE_PAYMENT_INSTRUMENT_V2: 'change-payment-instrument-v2',
	DELETE_CREDIT_CARD: 'delete-credit-card',
	DELETE_CREDIT_CARD_V2: 'delete-credit-card-v2',
	SHORE_PRODUCT_ADDED: 'shore-product-added',
	CANCEL_ORDER_PATH: 'cancel-order',
	SHORE_OUT_OF_STOCK: 'shore-out-of-stock',
	SHORE_POINTS_TRANSFER_MODAL_PATH: 'transfer',
	CANCEL_COMPLETE_PATH: 'cancel-complete',
	CHANGE_EXPRESS_PICKUP_STORE_PATH: 'change-express-pickup-store',
	CHANGE_PACKING_SLIP_PREFERENCE_MODAL_PATH: 'change-packingslip-preference',
	DIRECT_TO_BOOT_MODAL_PATH: 'direct-to-boot',
	DUAL_OFFER_MODAL_PATH: 'dual-offer',
	DIRECT_TO_BOOT_GROUPED_MODAL_PATH: 'direct-to-boot-grouped',
	PRODUCT_STORES_STOCK_LEVEL_MODAL_PATH: 'product-stores-stock-level',
	CHANGE_STOREFRONT_PICKUP_STORE_PATH: 'change-storefront-pickup-store',
	BOOST_RECEIVER_OFFER_MODAL_PATH: 'receive-boost-offer-email',
	DELIVERY_SAVER_MODAL_PATH: 'delivery-saver-popup',
	CHANGED_PRICE_MODAL_PATH: 'changed-price-modal',
	MEAL_PLANNING_CHOOSE_PLAN_PATH: 'choose-plan',
	CHANGED_PRICE_TUTORIAL_MODAL_PATH: 'changed-price-tutorial',
	PICKUP_POINT_INFORMATION_MODAL_PATH: 'pickup-point-information',
	EDR_BOOST_DETAILS_MODAL_PATH: 'boost-details',
};
