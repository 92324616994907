import { Injectable, DestroyRef, Inject } from '@angular/core';
import { Router, NavigationEnd, Event, ActivatedRoute } from '@angular/router'; // Added Event import
import { CustomWindow, FeatureService, WINDOW } from '@woolworthsnz/styleguide';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, withLatestFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SpaNavigationService {
	constructor(
		private readonly router: Router,
		private readonly featureService: FeatureService,
		private readonly destroyRef: DestroyRef,
		@Inject(WINDOW) public window: CustomWindow,
		private readonly activatedRoute: ActivatedRoute
	) {
		this.router.events
			.pipe(
				filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
				takeUntilDestroyed(this.destroyRef),
				withLatestFrom(
					this.featureService.isEnabled(ContextResponse.EnabledFeaturesEnum.CartologyGAMSessionGroup)
				)
			)
			.subscribe(([event, cartologyGAMSessionGroupEnable]) => {
				if (cartologyGAMSessionGroupEnable && this.window) {
					this.window.cartologyGAMSessionGroup = cartologyGAMSessionGroupEnable;
				} else if (this.window) {
					this.window.cartologyGAMSessionGroup = false;
				}
				const isSearchPage = event.url.includes('/shop/searchproducts');

				if (cartologyGAMSessionGroupEnable && !isSearchPage) {
					this.fireSpaPageChange(event);
				} else if (cartologyGAMSessionGroupEnable && isSearchPage) {
					this.window.cartologySearchTerm = this.activatedRoute.snapshot.queryParams['search'];
					this.fireSpaPageChange(event);
				}
			});
	}

	private fireSpaPageChange(event: NavigationEnd): void {
		window.console.log('Page changed (Angular Router):', event.url);
		const customEvent = new CustomEvent('spa-page-change', {
			detail: { path: event.url },
		});
		window.dispatchEvent(customEvent);
		this.handleAdBlocker();
	}

	private handleAdBlocker(): void {
		let googleAdsManagerTimeout = 4500;
		if (this.window?.adBlockerDetectionTimeout) {
			googleAdsManagerTimeout = this.window?.adBlockerDetectionTimeout;
			this.window.console.log(
				'AdBlocker detection timeout retrieved from Optimizely adBlockerDetectionTimeout: ',
				googleAdsManagerTimeout
			);
		} else {
			this.window.console.log('AdBlocker detection timeout set from hard coded value: ', googleAdsManagerTimeout);
		}

		setTimeout(() => {
			const gaBlocked = typeof this.window.ga === 'undefined' || typeof this.window.gtag === 'undefined';
			const gamBlocked = !this.window.googletag?.apiReady;
			const adblockerPresent = gaBlocked && gamBlocked; // Some ad-blockers will stub ga, gam - so we cannot exactly know if we are ad-blocked. This is covered in GAM Optimizely Script.

			if (adblockerPresent) {
				const bottomBanner = document.querySelector('#homepage-bottom-banner');
				if (bottomBanner) {
					bottomBanner.classList.add('hideHomepageBottomBanner');
				}
				const notifyReadyForDefaultImpressionObservabilityEvent = new CustomEvent(
					'promo-element-ready-for-default-impression-observability',
					{
						detail: {
							element: null,
						},
					}
				);
				window.dispatchEvent(notifyReadyForDefaultImpressionObservabilityEvent);
			}
		}, googleAdsManagerTimeout);
	}
}
