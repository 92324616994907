/* eslint-disable @typescript-eslint/naming-convention */
// A bunch of the interfaces in here are dicated by Datalyaer so have to break convention
import { ProductStockCodeQuantityPriceResponse, TrolleyItemResponse } from '@woolworthsnz/trader-api';

export class DatalayerEvent {
	event?: string;
	ecommerce: EcommerceDatalayerEvent;
	eventCallback?: Function;
}

export class FormFieldValidationEvent {
	event: 'formFieldValidationEvent';
	fieldName: string;
	filedValue: string;
	fieldValidated: 'Pass' | 'Fail';
}

export class RegistrationCompleteEvent {
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	event = DatalayerEventEnum.Registration;
	scvRegistration: 'complete';
	olsRegistration: 'complete' | 'incomplete';
}

export class EcommerceDatalayerEvent {
	currencyCode? = 'NZD';
	actionField?: { list: string };
	add?: {
		actionField?: { list: string };
		products: ProductDatalayerEvent[];
	};
	click?: {
		actionField?: { list: string };
		products: ProductDatalayerEvent[];
	};
	detail?: {
		actionField?: {
			list: string;
		};
		products: ProductDatalayerEvent[];
	};
	checkout?: {};
	impressions?: ProductDatalayerEvent[];
	remove?: {
		actionField: { list: string };
		products: ProductDatalayerEvent[];
	};
	promoView?: {
		promotions: Promotion[];
	};
	promoClick?: {
		promotions: Promotion[];
	};
}

export enum ProductType {
	Organic = 'Organic',
	CPP = 'CPP',
	ProductInGrid = 'Product in Grid',
	CartologyRemarketing = 'Cartology_Remarketing',
}

export class ProductDatalayerEvent {
	id: string;
	name?: string;
	price: number | null;
	availabilityStatus?: string;
	quantity?: number;
	saleUnit?: TrolleyItemResponse.UnitEnum; // unit - each or kg
	carouselName?: string;
	specialLabel?: string;
	position?: string | number;
	list?: string;
	productType?: ProductType;
	cppItemCount?: number;
}

export class PageInfoEvent {
	event: string;
	page_type: string;
	page_content: string;
	navigation?: Array<string>;
	filters?: Array<string>;
	sortBy?: string;
	pagination?: number;
	itemsPerPage?: number;
	itemsInResultsSet?: number;
	organicItemCount?: number;
	cppItemCount?: number;
	gridPromotionCount?: number;
}

export interface SearchPageInfoDetails {
	pageContent: string;
	navigation?: Array<string>;
	filters?: Array<string>;
	sortBy?: string;
	pagination?: number;
	itemsPerPage?: number;
	itemsInResultsSet?: number;
	organicItemCount?: number;
	cppItemCount?: number;
	gridPromotionCount?: number;
}

export interface ShopperTrackingEvent {
	event?: string;
	shopperId: string;
	scvId: string;
	sessionGroups: number[];
	orderCount: string;
	hasActiveDeliverySubscription: string;
}

export interface SessionSettingsEvent {
	event?: string;
	areaId?: number;
	fulfillmentMethod?: string;
	fulfillmentWindow?: string;
	storeId?: number; // This is fulfilmentStoreId (Trader) or onlineStoreId (ODS)
	pickUpType?: string;
	discountedWindow?: 'yes';
	discountValue?: number;
	isExpress?: boolean;
	isExpressAbandoned?: boolean;
}

export enum NotificationType {
	Accordion = 'accordion',
	Banner = 'notification_banner',
	BrandTileConversion = 'brand tile conversion',
	Button = 'button',
	ChangeMyOrder = 'change_my_order',
	Checkbox = 'check_box',
	CreditCard = 'credit_card',
	FamilyBar = 'family_bar',
	Global = 'global_navigation',
	Grocery = 'search_bar_grocery',
	ImageNavigation = 'image_navigation',
	Link = 'link',
	Modal = 'popup_modal',
	OrderSubstitution = 'order_substitution',
	RadioButton = 'radio_button',
	Recipe = 'search_bar_recipe',
	ScrollArrow = 'scroll arrow',
	SearchBar = 'searchbar',
	ServiceBar = 'service_bar',
	Stamp = 'stamp',
	Switch = 'toggle_switch',
	Tab = 'tab',
	TextField = 'text_field',
	Toggle = 'toggle',
}

export interface NotificationTrackingEvent {
	event: 'notification_event';
	notification_type: NotificationType;
	name: string;
	value?: string | number;
}
export interface NotificationEventWithInStockToggle extends NotificationTrackingEvent {
	in_stock_toggle: string;
}

export interface Promotion {
	id: string; // ID or Name is required.
	name: string;
	creative: string;
	position?: string;
}

export const DatalayerEventEnum = {
	AddToCart: 'addToCart',
	RemoveFromCart: 'removeFromCart',
	ProductClick: 'productClick',
	ProductImpressions: 'productImpressions',
	ProductDetail: 'productDetail',
	Checkout: 'checkout',
	PageInfo: 'pageinfo',
	Registration: 'registration',
	SessionSettings: 'sessionSettings',
	PromotionClick: 'promotionClick',
	PromotionImpression: 'promotionImpression',
	ChangeOrderEnabled: 'changeMyOrderEnabled',
	ChangeOrderDisabled: 'changeMyOrderDisabled',
	ChangeOrderTracking: 'changeMyOrderTracking',
	ChangeOrderSubmitted: 'changeMyOrderSubmitted',
	ViewItemList: 'viewItemList',
};

export const TealiumEventEnum = {
	ViewPromotion: 'view_promotion',
	SelectPromotion: 'select_promotion',
	SelectItem: 'select_item',
};

export const DatalayerCheckoutStepsEnum = {
	HaveYouForgotten: 'haveYouForgotten', // TODO:
	ShopperTrackingEvent: 'shopperTrackingEvent', // TODO:
	ReviewOrder: 'reviewOrder', // TODO:
	Payment: 'payment', // TODO:
};

export const CarouselDirectionEnum = {
	Left: 'left',
	Right: 'right',
};

// Change Order related events
export interface ChangeOrderEventDetail {
	category?: string;
	action?: string;
	label?: string;
	amendedTransactionId?: number;
	revisionNumber?: string;
}

export interface ChangeOrderEvent {
	event?: string;
	amendedTransactionId?: number;
	eventDetails?: ChangeOrderEventDetail;
}

export interface ChangeOrderTrackingEvent {
	event?: string;
	revisionNumber?: string;
	amendedTransactionId?: number;
	changeMyOrderStatus?: string;
}

export enum ChangeOrderStatus {
	enabled = 'enabled',
	disabled = 'disabled',
}

export enum ChangeOrderModificationType {
	add = 'add',
	remove = 'remove',
	edit = 'edit',
}

export interface ChangeOrderSubmittedEvent {
	event?: string;
	amendedTransactionId?: number;
	orderValueDifference?: string;
	originalOrderValue?: string;
	amendedOrderDetails?: {
		products: ChangeOrderProductDatalayerEvent[];
	};
	eventDetails?: {
		category?: string;
		action?: string;
		label?: string;
		amendedTransactionId?: number;
		revisionNumber?: string;
	};
}

export class ChangeOrderProductDatalayerEvent {
	id?: string;
	quantity?: string;
	amendedProductPrice?: string;
	amendedProductQuantity?: string;
	modificationType?: ChangeOrderModificationType;
	unitType?: TrolleyItemResponse.UnitEnum | ProductStockCodeQuantityPriceResponse.PricingUnitEnum; // unit - each or kg
}

export enum CardFieldType {
	newCard = 'new_card',
	savedCard = 'saved_card',
}

export interface ExperimentEvent {
	event: string;
	optimizelyDimensionValue: string;
}

export interface BrowseMenuExperimentEvent {
	event: string;
	tealium_event: string;
	content_type: string;
	content_id?: string;
	content_name: string;
	browse_category_name?: string;
	browse_category_level?: string;
}
